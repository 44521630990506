import { useCallback, useEffect, useState } from 'react';
import lamejs from 'lamejs'
import { GENERATE_SIGN_UPLOAD_FILE, FILE_UPLOADED } from '../services/files';
import { useApp } from '../contexts/app.context';
import moment from 'moment';
import { showNotification } from '@mantine/notifications';

const useRecorder = ({ company = null }) => {
    const [recording, setRecording] = useState(false);
    const [audio, setAudio] = useState<any>('');
    const [audioBlob, setAudioBlob] = useState<any>(null);
    const [timeCounter, setTimeCounter] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [fileId, setFileId] = useState(null);
    
    const { socket } = useApp();

    useEffect(() => {
        let interval: any = null;
        if (recording) {
            setTimeCounter(0);
            interval = setInterval(() => { setTimeCounter(prevCounter => prevCounter + 300); }, 300);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording]);

    const startRecording = () => {        
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks : any[] = [];

            mediaRecorder.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorder.addEventListener("start", event => {
                setRecording(true);
            });

            mediaRecorder.addEventListener("stop", async () => {
                setRecording(false);
                const audioBlob = new Blob(audioChunks, {type: "audio/mpeg"});
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioBlob(audioBlob);
                setAudio(audioUrl);
                stream.getTracks().forEach(track => track.stop());
            });

            setMediaRecorder(mediaRecorder);
            mediaRecorder.start();
        });
    };

    const saveAudio = () => {
        console.log({company, socket})
        return new Promise<{url: string}>((resolve, reject) => {
            GENERATE_SIGN_UPLOAD_FILE({
                size: audioBlob.size,
                filename: `${moment().format("YYYYMMDDHHmmss")}.mpeg`,
                contentType: "audio/mpeg",
                mimetype: "audio/mpeg",
            })
            .then(res => {
                fetch(res.signedUrl, {
                    method: 'PUT',
                    body: audioBlob,
                    headers: { 'Content-Type': "audio/mpeg" },
                })
                .then((uploadResponse) => {
                    if(uploadResponse.ok){
                        resolve({ url: res.generatedUrl })
                        FILE_UPLOADED(res._id, { url: res.generatedUrl })
                        .then(() => {  })
                        .catch(err => {
                            reject(err)
                        })
                    }else{
                        reject(new Error("Falha ao subir áudio, tente novamente"))
                    }
                })
                .catch(err => {
                    reject(err)
                })
            })
            .catch(err => {
                reject(err)
            })
        })
    }

    const stopRecording = () => {
        mediaRecorder && mediaRecorder.stop();
    }

    const clear = () => {
        stopRecording();
        setRecording(false);
        setAudio(null);
        setAudioBlob(null);
    }
    
    return { startRecording, audioBlob, stopRecording, timeCounter, recording, audio, saveAudio, clear }
};

export { useRecorder };
