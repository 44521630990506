import {
  ActionIcon,
  Box,
  Button,
  Center, CheckIcon, Grid, Group, Paper, PasswordInput,
  Select,
  Space,
  Text,
  TextInput, Title, UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { FaCheck } from "react-icons/fa";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { extenseRecurrenceType, formatMilliseconds, formatMoney, phoneCountryCodes, useQuery } from "../utility/util";
import { GET_ALL_PLANS } from "../services/plans";
import { PaymentForm } from "../components/payment_form";
import { useApp } from "../contexts/app.context";
import OtpInput from 'react-otp-input';
import { RECEIVE_OTP } from "../services/auth";
import moment from "moment";

export default function Signup({ customer = false, payment = false }){
  const { user, signIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { configs } = useApp();

  const [formData, setFormData] = useState<any>({
    general: {
      account_type: "1",
      employees: "1",
      phone_country_code: "55",
    },
    others: {},
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [otpRegenerateTimeout, setOtpRegenerateTimeout] = useState<number>(0);
  const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>("1-year");
  const [plans, setPlans] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([
  ]);

  const state = location.state as { from: Location };

  const query = useQuery();
  const queryFrom = query.get("from");
  const queryMail = query.get("email");
  const utm_source = query.get("utm_source");
  const utm_campaign = query.get("utm_campaign");

  const from = queryFrom ?? (state ? state.from.pathname : "/bem-vindo");

  const validTab = (customer
  ? [
    () => formData?.general?.name &&
      formData?.general?.phone_country_code &&
      formData?.general?.phone,
    () => (formData?.others?.otp ?? "").length === 4,
  ]
  : [
    () => formData?.company?.name && formData?.general?.account_type && formData?.general?.employees,
    () => formData?.general?.name &&
      formData?.general?.email &&
      formData?.general?.register_number &&
      formData?.general?.phone_country_code &&
      formData?.general?.phone,
    payment ? () => formData?.plan : null,
    payment ? () => formData?.payment?.method : null,
    // () => (formData?.others?.otp ?? "").length === 4,
    () => true,
  ]).filter(nn => nn);

  const signup = () => {
    if(!loading){
      setLoading(true)    
      
      api.post(customer ? `/auth/signup` : `/auth/signup/company`, formData)
      .then(({data : {user}}) => {
        setLoading(false);
        signIn(user, () => navigate(from, { replace: true }));
      })
      .catch(err => {
        setLoading(false);
        showNotification({title: "Ops", message: err.message, color: 'red'})
      })
    }
  }

  const navigateToTab = index => {
    if(index === steps.length) signup();
    else setSelectedTab(index);
  }

  const handleChangeGeneral = dt => setFormData(fd => ({...fd, general: {...fd?.general, ...dt}}))
  const handleChangeCompany = dt => setFormData(fd => ({...fd, company: {...fd?.company, ...dt}}))
  // const handleChangeOthers = dt => setFormData(fd => ({...fd, others: {...fd?.others, ...dt}}))

  const loadPlans = () => {
    GET_ALL_PLANS()
    .then(res => {
        setPlans(res);
    })
    .catch(err => { 
        showNotification({message: err.message, color: "red"})
    })
}

const generateOtp = () => {
  RECEIVE_OTP({
    country_code: formData?.general?.phone_country_code,
    phone: formData.general.phone,
  })
  .then(res => {
    // showNotification({ message: "Insira o código enviado para seu Whatsapp", color: "green" });
  })
  .catch(err => {
    showNotification({ message: err.message, color: "red" });
  })
}

useEffect(() => {
    loadPlans()
}, []);

useEffect(() => {
  // const isOtpStep = customer ? selectedTab === 1 : payment ? selectedTab === 4 : selectedTab === 2;
  const isOtpStep = customer ? selectedTab === 1 : false;
  if(isOtpStep){
    generateOtp();
    const resendAt = moment().add(2, "minutes");
    const otpInterval = setInterval(() => {
      const diff = moment(resendAt).diff(moment(), "seconds");
      setOtpRegenerateTimeout(diff > 0 ? diff : 0);
    }, 200)
    return () => {
      clearInterval(otpInterval)
    }
  }
}, [selectedTab, customer, payment]);

  useEffect(() => {
    handleChangeGeneral({email: queryMail})
  }, [queryMail])
  
  useEffect(() => {
    setFormData(fd => ({...fd, others: {
      ...fd?.others,
      utm_source,
      utm_campaign,
    }}))
  }, [utm_source, utm_campaign])
  
  const FirstStep = <Box>
    {/* <Title order={3} mt="md">Teste o sistema Aprova Aí por 7 dias grátis</Title>
    <Title order={5} mt="md" color="gray">Sem necessidade de informar o cartão de crédito ou qualquer tipo de fidelização.</Title> */}
    <Title order={3} mt="md">Escolha seu plano para continuar.</Title>
    {/* <Title order={5} mt="md" color="gray">Sem necessidade de informar o cartão de crédito ou qualquer tipo de fidelização.</Title> */}
    <Box mt="xl" mb="xl">
      <InputField
        title="Nome da(o) empresa/profissional"
        mb="lg"
        name="name"
        value={formData?.company?.name}
        onChange={handleChangeCompany}
      />
      <InputField
        mb="lg"
        name="account_type"
        value={formData?.general?.account_type}
        fieldType={InputFieldTypes.BOX}
        onChange={handleChangeGeneral}
        options={[
          {label: "Conta pessoal", value: "1"},
          {label: "Conta empresarial", value: "2"},
        ]}
      />
      <InputField
        title="Quantidade de colaboradores"
        mb="lg"
        name="employees"
        value={formData?.general?.employees}
        fieldType={InputFieldTypes.BOX}
        onChange={handleChangeGeneral}
        options={[
          {label: "1", value: "1"},
          {label: "2-10", value: "2-10"},
          {label: "11-50", value: "11-50"},
          {label: "50+", value: "50+"},
        ]}
      />
    </Box>
  </Box>;

  const SeccondStep = <Box>
    <Title order={3} mt="md">Informações do usuário</Title>
    <Title order={5} mt="md" color="gray">Informe os dados do usuário e senha para acesso ao sistema.</Title>
    <Box mt="xl" mb="xl">
      <InputField
        title="E-mail"
        mb="lg"
        name="email"
        value={formData?.general?.email}
        onChange={handleChangeGeneral}
      />
      <InputField
        title={customer ? "Nome Completo" : "Nome da(o) administrador(a)"}
        mb="lg"
        name="name"
        value={formData?.general?.name}
        onChange={handleChangeGeneral}
      />
      {!customer && <InputField
        title="CPF/CNPJ"
        mb="lg"
        name="register_number"
        value={formData?.general?.register_number}
        onChange={handleChangeGeneral}
      />}
      {<Group style={isMobile ? {display: 'block'} : {}}>
        <InputField
          style={isMobile ? {} : {flex: 0.3}}
          title={"País"}
          mb="lg"
          name="phone_country_code"
          autoComplete="tel-country-code"
          fieldType={InputFieldTypes.SELECT}
          value={formData?.general?.phone_country_code}
          onChange={handleChangeGeneral}
          nothingFound="No country code found"
          options={phoneCountryCodes}
        />
        <InputField
          title="Whatsapp"
          mb="lg"
          name="phone"
          style={{flex: 1}}
          inputMode="numeric"
          mask={
            ((formData?.general?.phone_country_code) === "55")
            ? InputFieldMasks.CUSTOM
            : undefined
          }
          customMask={formData?.general?.phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"}
          value={formData?.general?.phone}
          onChange={handleChangeGeneral}
        />
    </Group>}
      <InputField
        title="Senha"
        mb="lg"
        name="password"
        fieldType={InputFieldTypes.PASSWORD}
        value={formData?.general?.password}
        onChange={handleChangeGeneral}
      />
    </Box>
  </Box>;

  const OtpStep = <Box>
    <Text ta="center" mb="xl" size="sm">Insira o código enviado para seu Whatsapp <b>+{formData?.general?.phone_country_code}{(formData?.general?.phone ?? "").replace(/ |\-|\(|\)|\_/g, "")}</b></Text>
    
    <Group style={{justifyContent: 'center'}}>
      <OtpInput
        value={formData?.others?.otp}
        inputType="number"
        onChange={vl => setFormData(fd => ({ ...fd, others: { ...fd.others, otp: vl } }))}
        numInputs={4}
        inputStyle={{
          fontSize: 40,
          width: '1.4em',
        }}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input inputMode="numeric" type="number" {...props} />}
      />
    </Group>

    <Text mt="xl" ta="center" size="sm">Ainda não recebeu o código? {
      otpRegenerateTimeout > 0
      ? <b>Envie novamente em {formatMilliseconds(otpRegenerateTimeout * 1000)}</b>
      : <a href="javascript:void(0)" onClick={generateOtp}>Re-enviar Código</a>
    }
    </Text>
  </Box>

  const ThirdStep = <Box>
    <Title order={3} mt="md">Teste o sistema Aprova Aí por 7 dias grátis</Title>
    <Title order={5} mt="md" color="gray">Sem necessidade de informar o cartão de crédito ou qualquer tipo de fidelização.</Title>
    <Box mt="xl" mb="xl">
    <Group pb="md" pt="md" style={{justifyContent: 'center'}} align='center'>
                <Group spacing={5} style={{borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF', whiteSpace: 'nowrap'}}>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "1-month" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("1-month")}
                        variant={selectedRecurrencyType === "1-month" ? "filled" : "light"} size="xs">Mensal</Button>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "3-month" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("3-month")}
                        variant={selectedRecurrencyType === "3-month" ? "filled" : "light"} size="xs">Trimestral</Button>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "6-month" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("6-month")}
                        variant={selectedRecurrencyType === "6-month" ? "filled" : "light"} size="xs">Semestral</Button>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "1-year" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("1-year")}
                        variant={selectedRecurrencyType === "1-year" ? "filled" : "light"} size="xs">Anual</Button>
                </Group>
            </Group>
        
            <Grid mt="md" mb="md">
                {plans
                .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === selectedRecurrencyType)
                .map(plan => (
                    <Grid.Col md={4}>
                        <UnstyledButton style={{width: '100%'}} onClick={() => {
                            setFormData(fd => ({...fd, plan: plan._id}))
                        }}>
                            <Paper shadow='xs' p="md" style={{border: formData.plan === plan._id ? '3px solid #242442' : undefined}}>
                                <Title order={3}>{plan.title}</Title>
                                <Text c="gray" size="sm">{plan.description}</Text>

                                <Group mt="md" align='flex-end' spacing="1px">
                                    <Title size="22px">{formatMoney(plan.price)}</Title>
                                    <Text size="sm">/ {extenseRecurrenceType(plan.recurrence_type ?? "month")}</Text>
                                </Group>
                                {/* <Group align='center' justify='flex-end' mt="xs">
                                    {(plan.service.badges ?? []).map(s => ( <Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge> ))}
                                </Group> */}
                                {/* <Text c="gray" size="sm">Per user</Text> */}
                                <Button mt="md" fullWidth variant="light">Selecionar</Button>
                            </Paper>
                        </UnstyledButton>
                    </Grid.Col>
                ))}
            </Grid>
    </Box>
  </Box>;
  
  const PaymentStep = <Box>
    <Title order={3} mt="md">Dados para pagamento</Title>
    <Title order={5} mt="md" color="gray">O pagamento será efetuado apenas caso goste da plataforma após os 7 dias grátis.</Title>

    <PaymentForm
      onChange={(dt) => setFormData(fd => ({ ...fd, payment: { ...fd?.payment, ...dt } }))}
      data={formData.payment}
      showMethods={false}
      customerData={{
        email: formData?.general?.email,
        name: formData?.general?.name,
        document: formData?.general?.register_number,
        phone: `${formData?.general?.phone_country_code ?? '55'}${formData?.general?.phone}`,
      }}
      onSave={(methodId) => {
        setFormData(fd => ({ ...fd, payment: { ...fd?.payment, method: methodId } }));
        navigateToTab(selectedTab + 1)
      }}
    />
  </Box>

  const FourthStep = <Box>
    <Title order={3} mt="md">Formulário preenchido!</Title>
    <Title order={5} mt="md" color="gray">Clique em confirmar dados e aproveite tudo que o Aprova Aí pode fazer por fazer!.</Title>
    <Paper mt="xl" mb="xl" shadow="xs" p="md">
      <Title order={4} color="lime">Muito bem!</Title>
      <Title order={5} color="gray">Ao clicar em confirmar dados você concorda que leu e aceita a Política de Privacidade do Sistema.</Title>
    </Paper>
  </Box>;

  const activeForm = (customer ?
  [
    SeccondStep,
    OtpStep,
  ]
  : [
    FirstStep,
    SeccondStep,
    payment ? ThirdStep : null,
    payment ? PaymentStep : null,
    // OtpStep,
    FourthStep,
  ].filter(nn => nn))[selectedTab]

  useEffect(() => {
    if(customer){
      setSteps([
        {title: "Dados do usuário", subtitle: "Informações de acesso"},
        {title: "Verificação", subtitle: "Autenticação do seu whatsapp"},
      ])
    }else{
      setSteps([
        {title: "Dados da empresa/profissional", subtitle: "Informações do tipo de conta"},
        {title: "Dados do usuário", subtitle: "Informações de acesso"},
        payment ? {title: "Plano", subtitle: "Escolha o seu plano"} : null,
        payment ? {title: "Assinatura", subtitle: "Dados para pagamento"} : null,
        {title: "Verificação", subtitle: "Autenticação do seu whatsapp"},
      ].filter(nn => nn))
    }
  }, [customer, payment])

  return !!user
  ? <Navigate to="/" state={{ from }} />
  : (
    <Grid>
      <Grid.Col xs={12} md={4} style={{padding: 0}}>
        <div style={{
          padding: "40px 40px",
          boxShadow: "0px 4px 4px #CACACA77",
          minHeight: isMobile ? 'auto' : 'calc(100vh + 8px)',
          overflowY: 'auto',
          background: '#FEFEFE'
        }}>
          <div style={{display: 'flex', margin: '40px 0' ,flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <img alt="AprovaAi" style={{height: 80, width: "60%", objectFit: 'contain'}} src={configs.image ?? "/logo.png"}/>
          </div>
          <div>
            {steps.map((step, index) => (
              <UnstyledButton
                disabled={selectedTab > index ? false : (validTab[selectedTab] ? !validTab[selectedTab]() : false)}
                onClick={() => navigateToTab(index)} style={{width: '100%'}}
              >
                <Group mt="md" mb="md">
                  <ActionIcon size="xl" color="lime" variant={selectedTab > index ? "light" : selectedTab === index ? "filled" : "outline"}>
                    {selectedTab > index
                      ? <FaCheck color="lime" size={14} />
                      : <Title order={4} color={selectedTab >= index ? "white" : "lime"}>{index + 1}</Title>}
                  </ActionIcon>
                  <Box>
                    <Title order={5}>{step.title}</Title>
                    <Text color="gray">{step.subtitle}</Text>
                  </Box>
                </Group>
              </UnstyledButton>
            ))}
          </div>
        </div>
      </Grid.Col>
      <Grid.Col xs={12} md={8}>
        <div style={{padding: isMobile ? '40px' : '80px 140px'}}>
          <form onSubmit={e => {e.preventDefault(); navigateToTab(selectedTab+1) }}>
            {activeForm}
            <Group position="right" mt="md">
              <Button
                disabled={validTab[selectedTab] ? !validTab[selectedTab]() : false}
                type="submit"
                color="lime"
                id={selectedTab+1 === steps.length ? "StartTrial" : "Continue"}
                size="md">{selectedTab+1 === steps.length ? "Cadastrar" : "Continuar"}</Button>
            </Group>
          </form>
        </div>
      </Grid.Col>
    </Grid>
  );
};
